import * as Sentry from "@sentry/vue";

export default function initializeSentry(app, config = {}) {
    const dsn = import.meta.env.VITE_SENTRY_DSN;
    const release = import.meta.env.VITE_APP_VERSION;
    const tracesSampleRate = Number.parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0);
    const replaysSessionSampleRate = Number.parseFloat(import.meta.env.VITE_REPLAYS_SESSION_RATE ?? 0);
    const replaysOnErrorSampleRate = Number.parseFloat(import.meta.env.VITE_REPLAYS_ERROR_RATE ?? 0);

    if (dsn && dsn !== null) {
        Sentry.init({
            app,
            dsn,

            // Types of errors to ignore (e.g. browser extensions)
            ignoreErrors: [
                // Random plugins/extensions
                "top.GLOBALS",
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                "originalCreateNotification",
                "canvas.contentDocument",
                "MyApp_RemoveAllHighlights",
                "http://tt.epicplay.com",
                "Can't find variable: ZiteReader",
                "jigsaw is not defined",
                "ComboSearch is not defined",
                "http://loading.retry.widdit.com/",
                "atomicFindClose",
                // Facebook borked
                "fb_xd_fragment",
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                "bmi_SafeAddOnload",
                "EBCallBackMessageReceived",
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                "conduitPage",
                // Generic error code from errors outside the security sandbox
                // You can delete this if using raven.js > 1.0, which ignores these automatically.
                "Script error.",
                // Avast extension error
                "_avast_submit",
                "Illegal invocation",
                "N.at is not a function",
                // Error cause by Sentry
                "DataCloneError",
                // Wordpress error
                "escapeAmpersand",
                // Mobile Safari error when trying to view eBook
                "Importing a module script failed",
                // Deployment errors
                "Failed to fetch dynamically imported module",

                // PDF.js errors
                "pdfjs-dist/build/pdf",

                // SweetAlert2 errors
                "sweetalert2/dist/sweetalert2.all",
            ],

            // Places to ignore errors from (e.g. browser extensions)
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            ],

            // Alternatively, use `process.env.npm_package_version` for a dynamic release version
            // if your build tool supports it.
            release,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate,

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate,

            ...config,

            integrations: [Sentry.replayIntegration(), ...(config?.integrations ?? [])],
        });
    }
}
