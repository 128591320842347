<script>
import { mapActions, mapGetters } from "vuex";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import TestContentBlockPlaceholder from "@/components/TestTaking/TestContentBlockPlaceholder";
import { useBroadcastChannel } from "@vueuse/core";
import ContentRender from "@/components/TestTaking/ContentRender.vue";

export default {
    setup() {
        const { isSupported, data } = useBroadcastChannel({ name: "preview_channel" });

        return {
            isBCSupported: isSupported,
            channelData: data,
        };
    },

    components: {
        ContentRender,
        TestContentBlockPlaceholder,
        BtnPrimary,
    },

    mounted() {
        // If Broadcast Channels are supported
        if (this.isBCSupported) {
            this.$watch(
                () => this.channelData,
                (value) => {
                    // When broadcast channel receives a message, reload the page
                    window.location.reload();
                },
            );
        }
    },

    computed: {
        ...mapGetters({
            _getNextPage: "getNextPage",
            _graded: "graded",
            _isLastContentPage: "isLastContentPage",
            _isPreview: "isPreview",
            _isSample: "isSample",
            _navigating: "navigating",
            _numberOfPages: "numberOfPages",
            _page: "page",
            _pageNumber: "pageNumber",
            _saving: "saving",
            _testSubmitted: "testSubmitted",
        }),

        buttonText() {
            if (this.saving) {
                return "Saving";
            }

            return !this._isLastContentPage ? "Go to next page" : "Review my test";
        },

        disableButtons() {
            return this.saving || this._navigating;
        },

        displayButton() {
            return (
                this.hasNextPage &&
                (!this._isLastContentPage ||
                    (this._isLastContentPage && !this._isSample && !this._isPreview && !this._graded))
            );
        },

        hasNextPage() {
            return this._getNextPage(1);
        },

        needsTopPadding() {
            const name = _.get(_.last(this._page.content), "name");
            return !!(name !== undefined && name.indexOf("questions") === -1);
        },

        saving() {
            return this._saving;
        },
    },

    methods: {
        ...mapActions({
            _goToReview: "goToReview",
            _navigateToPage: "navigateToPage",
            _updateLastCompletedPage: "updateLastCompletedPage",
            _createSubmission: "createSubmission",
        }),

        changeSaving(value) {
            this.saving = value;
        },

        async scrollTestPageToTop() {
            const pageContainerElement = document.getElementById("test-page-container");
            pageContainerElement.scrollTo(0, 0);
        },

        nextPage() {
            if (this._navigating || this.disableButtons) {
                return;
            }

            const pageNumber = this._pageNumber;

            if (this._pageNumber < this._numberOfPages) {
                this._navigateToPage(this._getNextPage(1)).then(() => {
                    this.scrollTestPageToTop();

                    this._updateLastCompletedPage(pageNumber);
                });
            } else {
                this._goToReview().then(() => {
                    this._updateLastCompletedPage(pageNumber);
                });
            }
        },
    },
};
</script>

<template>
    <div
        class="test-page wp-embed-responsive mx-auto w-full max-w-1180p flex-1 pb-50p"
        id="test-page"
    >
        <div
            v-for="block in _page.content"
            :key="block.clientId"
        >
            <ContentRender
                :block="block"
                @change-saving="changeSaving"
            />
        </div>

        <div
            class="flex h-100p w-full flex-row-reverse"
            :class="{ 'mt-5': needsTopPadding }"
        >
            <div>
                <BtnPrimary
                    arrow
                    arrow-class="ml-4"
                    class="h-75p"
                    :disabled="disableButtons"
                    @click="nextPage"
                    v-if="displayButton"
                >
                    <FontAwesomeIcon
                        icon="redo-alt"
                        :spin="saving"
                        flip="horizontal"
                        size="lg"
                        class="mx-3"
                        v-if="saving"
                    />
                    {{ buttonText }}
                </BtnPrimary>
            </div>
        </div>
    </div>
</template>
