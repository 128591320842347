<script lang="ts" setup>
import Test from "@/types/Test";
import { computed, onMounted, ref } from "vue";
import CText from "../Form/CText.vue";

const props = defineProps({
    tests: {
        type: Object as PropType<Test>,
        default: null,
    },
    includeTestTitle: {
        type: Boolean,
        default: true,
    },
});

const searchStandard = ref("");
const standards = ref({});

const sortKeys = (data) => {
    const sortedObject = {};
    Object.keys(data)
        .sort()
        .forEach((v, i) => {
            sortedObject[v] = data[v];
        });

    return sortedObject;
};
const setStandards = (test) => {
    if (!test.pages) {
        return;
    }

    test.pages.forEach((page) => {
        page.questions?.forEach((question) => {
            if (question.standard) {
                // Split standards by comma
                question.standard.split(", ").forEach((aStandard) => {
                    if (aStandard !== ", ") {
                        // Add standard
                        aStandard = aStandard.trim();
                        // Remove last comma

                        if (aStandard.substring(aStandard.length - 1) === ",") {
                            aStandard = aStandard.substring(0, aStandard.length - 1);
                        }

                        // Create standard object if new
                        if (!standards.value[aStandard]) {
                            standards.value[aStandard] = {};
                        }

                        // Add question #
                        let { number } = question;
                        // Remove last period
                        if (number.substring(number.length - 1) === ".") {
                            number = number.substring(0, number.length - 1);
                        }

                        if (!standards.value[aStandard][test.title]) {
                            standards.value[aStandard][test.title] = { questions: [] };
                        }
                        standards.value[aStandard][test.title].questions.push(number);
                    }
                });
            }
        });
    });
};
const createStandardReport = () => {
    props.tests.forEach((test) => {
        setStandards(test);
    });

    if (Object.keys(standards.value).length > 0) {
        standards.value = sortKeys(standards.value);
    }
};

const filteredStandards = computed(() => {
    if (searchStandard.value) {
        const records = {};
        Object.keys(standards.value).forEach((key) => {
            if (String(key).toLowerCase().includes(searchStandard.value.toLowerCase())) {
                records[key] = standards.value[key];
            }
        });
        return records;
    }
    return standards.value;
});

onMounted(() => {
    createStandardReport();
});
</script>

<template>
    <div>
        <div class="mb-2">
            <CText
                v-model="searchStandard"
                placeholder="Enter standard..."
                :label="'Search Standards:'"
            />
        </div>

        <table
            v-if="Object.keys(filteredStandards).length > 0"
            class="w-full text-sm"
        >
            <tr>
                <th class="border border-gray-400 px-2 py-2 lg:w-1/4">Standard</th>

                <th class="border border-l-0 border-gray-400 px-2 py-2">
                    {{ includeTestTitle ? "Tests And Questions" : "Questions" }}
                </th>
            </tr>

            <tr
                v-for="(standardObject, standardTitle) in filteredStandards"
                class="even:bg-gray-100"
            >
                <td class="border border-gray-400 px-2 py-2 text-left align-top">{{ standardTitle }}</td>

                <td class="border border-l-0 border-gray-400 px-2 py-2 text-left">
                    <div v-for="(testObject, testTitle) in standardObject">
                        <span v-if="includeTestTitle">{{ testTitle }}:</span>

                        <span class="text-gray-600">{{ testObject.questions.join(", ") }}</span>
                    </div>
                </td>
            </tr>
        </table>

        <div
            v-else
            class="pt-4 text-center text-gray-700"
        >
            No standards found...
        </div>
    </div>
</template>

<style scoped></style>
