import { compile, h } from "vue";

// this is defined as a functional component instead of a regular SFC so that we can use the render function
// Functional components - https://vuejs.org/guide/extras/render-function.html#functional-components
// don't use render with <script setup> - https://github.com/vuejs/core/issues/4980

export default function component(props) {
    return h({
        // <ht> is a custom element used in HotText components and needs to be handled here.
        // This anonymous component tries to render <ht> tags, so we have to tell it to ignore it.
        // math-field is inserted from math content in question text and needs to be ignored here.
        render: compile(`<div> ${props.content} </div>`, {
            isCustomElement: (tag) => ["ht", "math-field"].includes(tag),
        }),
    });
}
